import React, { useEffect, useState } from 'react'
import api from '../../api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';
import FaqForm from './FaqForm';
import EditFaqForm from './EditFaqForm';
function FaqList() {
    const [faq, setFaq] = useState([]);
    const [show, setShow] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const handleClose = () => setShow(false);

    const [faqId, SetfaqId] = useState("");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'title': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN }
    });



    const fetchFaq = () => {
        api.get(`ServicesFaq/`).then((res) => {
            const faqs = res.data.result.servicesFaq;
            //console.log('Categories', categories);
            setFaq(faqs);
        });
    };
    useEffect(() => {
        fetchFaq()
    }, []);
    const actionBodyTemplate = (rowData) => {
        //onClick={() => editProduct(rowData)}
        // onClick={() => confirmDeleteProduct(rowData)} 
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded className="mr-2 rounded-5" 
               onClick={() => handleEdit(rowData.id)} 
                    />
                <Button icon="pi pi-trash" rounded severity="danger" />
            </React.Fragment>
        );
    };
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={handleCreate} />
                {/* <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </div>
        );
    };
    const handleCreate = () => {
        //alert('ok');
        setShow(true);
        setCreateMode(true);
        setEditMode(false);
        setDeleteMode(false);
    };
    const handleEdit = (id) => {
      //   console.log('Le ID EST :', id);
        setShow(true);
        setEditMode(true);
        SetfaqId(id);
        setCreateMode(false); 
        setDeleteMode(false);
      };
    return (
        <>
            <Toolbar className="mb-4" start={leftToolbarTemplate}></Toolbar>
            <DataTable value={faq} size={'small'} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                <Column field="title" sortable header="Titre" className='font-weight-bold'></Column>
                <Column field="service.title" sortable header="Service"></Column>
                <Column field="body" header="Description"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>
            {/*modal  */}
            {createMode && (
                <FaqForm
                    show={show}
                    handleClose={handleClose}
                    reFresh={fetchFaq}
                />
            )}
            {editMode && (
                <EditFaqForm
                    show={show}
                    onClose={handleClose}
                    reFresh={fetchFaq}
                    faqId={faqId}
                />
            )}
        </>
    )
}

export default FaqList