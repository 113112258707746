import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../api';
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
function FaqForm({ show, handleClose, reFresh }) {
  const notify = () => toast.success('Votre FAQ a été enregistré avec succès!');
  const [service, SetService] = useState([]);
  const [formData, setFormData] = useState({
    titre: '',
    description: '',
    category: '',
  });
  const fetchService = () => {
    api.get(`services/getCombox`).then((res) => {
      const service = res.data.result.servics;
      console.log('hello service', service);
      SetService(service);
    });
  };

  const handleCategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption.value
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Vous pouvez ici gérer l'envoi des données au serveur
    console.log('Post Submitted:', formData);
    api.post(`/ServicesFaq/add`, formData).then((res) => {
      if (res.data.status === "OK") {
        notify();
        setFormData({
          titre: '',
          description: '',
          category: '',
        })

      } else {
        // this.ErrorSwal();
      }
    });
  };
  useEffect(() => {
    fetchService();
  }, []);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Toaster />
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Création de FAQ</Modal.Title>
          </Modal.Header>

          <Modal.Body>


            <Form.Group controlId="formTitre">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                name="titre"
                placeholder="Entrer le titre de la FAQ"
                value={formData.titre}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name='description'
                rows={3}
                placeholder="Entrer la description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="formServiceAssocie">
        <Form.Label>Service associé</Form.Label>
        <Form.Control
          as="select"
          value={serviceAssocie}
          onChange={(e) => setServiceAssocie(e.target.value)}
          required
        >
          <option value="">Sélectionner un service</option>
          <option value="Service 1">Service 1</option>
          <option value="Service 2">Service 2</option>
          <option value="Service 3">Service 3</option>
        </Form.Control>
      </Form.Group> */}

            <Form.Group controlId="formCategory" className='mb-3'>
              <Form.Label>Service</Form.Label>
              <Select
                required
                options={service}
                onChange={handleCategoryChange}
                placeholder="Sélectionnez un service..."
                isSearchable
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default FaqForm