import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../api';
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
function EditFaqForm({ onClose, show, reFresh, faqId }) {
  const notify = () => toast.success('Votre FAQ a été enregistré avec succès!');
  const [service, SetService] = useState([]);
  const [formData, setFormData] = useState({
    titre: '',
    description: '',
    category: '',
  });
  const fetchService = () => {
    api.get(`services/getCombox`).then((res) => {
      const service = res.data.result.servics;
      console.log('hello service', service);
      SetService(service);
    });
  };

  const fecthServicesFaq = () => {
    api.get(`ServicesFaq/getServicesFaq/` + faqId).then((res) => {
      console.log('loggg', res);
      setFormData({
        titre: res.data.result.servicesFaq[0].title,
        description: res.data.result.servicesFaq[0].body,
        //category: res.data.result.servicesFaq[0].service.title,
      });
    });
  }

  const handleCategoryChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption.value
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Vous pouvez ici gérer l'envoi des données au serveur
    console.log('Post Submitted:', formData);
   // const data = new FormData();
    //console.log(data);
    api.post(`/ServicesFaq/edit/` + faqId, formData).then((res) => {
      if (res.data.status === "OK") {
        notify();
        setFormData({
          titre: '',
          description: '',
          category: '',
        })
        reFresh();
        if (onClose) onClose();
      } else {
        // this.ErrorSwal();
      }
    });
  };



  useEffect(() => {
    fetchService();
    if (faqId) {
      fecthServicesFaq();
    }

  }, [faqId]);
  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Toaster />
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Modification de FAQ</Modal.Title>
          </Modal.Header>

          <Modal.Body>


            <Form.Group controlId="formTitre">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                name="titre"
                placeholder="Entrer le titre de la FAQ"
                value={formData.titre}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name='description'
                rows={3}
                placeholder="Entrer la description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCategory" className='mb-3'>
              <Form.Label>Service</Form.Label>
              <Select
                required
                // defaultValue={service[0]}
                options={service}
                onChange={handleCategoryChange}
                placeholder="Sélectionnez un service..."
                isSearchable
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default EditFaqForm